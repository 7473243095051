// Component Appearance
// --------------------

// Appearance classes contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)
// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms
// - Google CSE
// - October

// Prototypes
// --------------------------------------------------------
@mixin linkBlack {
  @include transition($duration color $timing);

  color: $neutral-black;

  a:hover &,
  &:hover {
    color: $linkHover;
  }
}

// Prototypes
// --------------------------------------------------------
.accent-primary {
  color: $accent-primary;
}

// Utilities
// --------------------------------------------------------
.active-video {
  &:hover {
    .program-title {
      text-decoration: underline;
    }
  }
}

// Layout
// --------------------------------------------------------
body.screen {
  color: $neutral-white;
  background-color: $neutral-black;
}

.block-neutral10 {
  padding: 1.25em 1.5em;
  background-color: $neutral-gray10;
}

.block-neutral10.image-tab {
  position: relative;
  padding: 2.688em 2.906em 3.188em;
  background-color: $neutral-gray10;

  .col-three-fourths {
    padding-right: 0.625em;
  }
}

.block-neutral-black {
  color: $neutral-white;
  background-color: $neutral-black;
}

.block-off-white {
  background-color: $neutral-off-white;
}

.block-primary {
  color: $neutral-white;
  background-color: $accent-primary;

  .icon-x {
    color: $neutral-white;
  }
}

.block-spectrum {
  &.primary,
  &.grand-ave {
    color: $neutral-white;
    background-color: $spectrum-primary;
  }

  &.secondary,
  &.geffen-contemporary {
    color: $neutral-white;
    background-color: $spectrum-secondary;
  }

  &.tertiary,
  &.pacific-design-center {
    color: $neutral-white;
    background-color: $spectrum-tertiary;
  }

  &.quaternary,
  &.double-negative {
    color: $neutral-white;
    background-color: $spectrum-quaternary;
  }
}

.block-spectrum20 {
  padding: 0.625em 1.125em 1.125em;

  &.primary,
  &.grand-ave {
    background-color: $spectrum-primary-light;
  }

  &.secondary,
  &.geffen-contemporary {
    background-color: $spectrum-secondary-light;
  }

  &.tertiary,
  &.pacific-design-center {
    background-color: $spectrum-tertiary-light;
  }

  &.quaternary,
  &.double-negative {
    background-color: $spectrum-quaternary-light;
  }
}

.block-spectrum10 {
  padding: 1.5em 1.688em 1.438em;

  &.primary,
  &.grand-ave {
    background-color: $spectrum-primary-pale;
  }

  &.secondary,
  &.geffen-contemporary {
    background-color: $spectrum-secondary-pale;
  }

  &.tertiary,
  &.pacific-design-center {
    background-color: $spectrum-tertiary-pale;
  }

  &.quaternary,
  &.double-negative {
    background-color: $spectrum-quaternary-pale;
  }
}

.block-neutral50 {
  border: 1px solid $neutral-gray50;
}

// <figcaption> Used in shopify product grid
.grid-callout-primary {
  .label {
    display: block;
    margin-bottom: 1.2rem;

    @include respond($break40) {
      display: none;
    }

    @include respond($break60) {
      display: block;
    }
  }

  header {
    @include font-head;

    font-size: $type110;

    @include respond($break40) {
      font-size: $type90;
    }

    @include respond($break60) {
      font-size: $type110;
    }

    @include respond($break70) {
      font-size: $sign10;
    }
  }
}

.overlay-white {
  background-color: transparentize($neutral-white, 0.08);

  &.overlay-compatibility-error {
    background-image: url("/themes/moca-la/assets/style/images/fallback_white-transparent92.png");
    background-repeat: repeat;
  }
}

.overlay-dark {
  background-color: transparentize($neutral-black, 0.08);

  &.overlay-compatibility-error {
    background-image: url("/themes/moca-la/assets/style/images/fallback_black-transparent92.png");
    background-repeat: repeat;
  }

  .overlay-close {
    color: $neutral-white;
  }
}

@mixin block-rule($position: top) {
  border-#{$position}: 1px solid $neutral-gray55;
  padding-#{$position}: 1.563em;
}

@mixin block-rule-short($position: top) {
  position: relative;
  padding-#{$position}: 1.563em;

  &::before {
    position: absolute;
    left: 0;
    width: 15.25rem;
    max-width: 100%;
    height: 2px;
    content: "";
    background-color: $neutral-gray50;
    #{$position}: 0;

    @include respond($break40) {
      height: 6px;
    }
  }
}

.block-rule-top {
  @include block-rule;
}

.block-rule-short-top {
  @include block-rule-short;
}

.section-rule-top + .section-rule-top {
  @include block-rule;

  padding-top: 1.938em;
}

// Remove top-rules and spacing for subsections
.section-rule-top.content-normal + .section-rule-top.content-subsection {
  margin-top: 1.875em;
  border-top: none;
}

.section-rule-top.content-lead + .section-rule-top.content-normal {
  margin-top: 1.875em;
}

.section-rule-top.content-lead + .section-rule-top.content-subsection {
  margin-top: 1.875em;
}

.section-rule-top.content-subsection + .section-rule-top.content-subsection {
  margin-top: 1.875em;
  border-top: none;
}

.nav-dropdown-panel {
  padding: 0;

  &::before {
    position: absolute;
    top: -0.813em;
    width: 100%;
    height: 0.813em;
    content: "";
    background-color: $neutral-white;
  }
}

// <a>
// Element level scoping required to override RTE content defualts
a.page-badge {
  @include label-prototype;

  position: absolute;
  top: -3.125rem;
  right: -2.5rem;
  z-index: 10;
  display: block;
  width: 7.375rem;
  height: 7.375rem;
  line-height: 7.375rem;
  color: $neutral-white;
  text-align: center;
  text-decoration: none;
  background-color: $accent-primary;
  border-radius: 100%;

  .fixed & {
    position: absolute;
    top: 0;
  }
}

.lightbox,
.lightbox-overlay {
  background-color: transparentize($neutral-white, 0.08);

  header {
    color: $neutral-white;
    background-color: $neutral-off-black;
  }

  .popup-bottom {
    background-color: $neutral-off-black;
  }

  .nav-social {
    background-color: $neutral-off-black;

    a {
      color: $neutral-white;
    }
  }
}

.lightbox-caption {
  color: $neutral-black;

  .heading-secondary {
    margin-bottom: 0.313rem;
  }

  p {
    @include copy-small;
  }
}

.lightbox-controls {
  .lightbox-close {
    @include icon-pre($x);
    @include button-unstyled;

    color: $accent-primary;
    cursor: pointer;

    &:hover {
      color: $linkHover;
    }
  }

  .lightbox-previous,
  .lightbox-next {
    @include button-unstyled;

    display: inline-block;
    color: $accent-primary;
    cursor: pointer;
    background: $neutral-white;
    border-radius: 100%;

    &::before,
    &::after {
      display: block;
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  .lightbox-next {
    @include icon-post($arrow-circled-right);

    font-size: 0;

    &::after {
      font-size: 2.625rem;
    }
  }

  .lightbox-previous {
    @include icon-post($arrow-circled-left);

    font-size: 0;

    &::after {
      font-size: 2.625rem;
    }
  }
}

.ghost-nav {
  .nav-trigger {
    @include vendor-transition(all, $duration, $timing);
  }

  .nav-hidden {
    @include vendor-transition(all, $duration, $timing);

    visibility: hidden;
    opacity: 0;
  }

  .container-title:hover + & .nav-hidden,
  &:hover .nav-hidden,
  .nav-hidden.active,
  .nav-hidden:hover {
    visibility: visible;
    opacity: 1;
  }
}

.container-header-fixed {
  background-color: $neutral-white;
}

.footer-block-phone {
  padding: 1.25em $containerPaddingPhone;
  background-color: $neutral-gray10;

  @include container-escape-phone;

  @include respond($break20) {
    padding: 0;
    background-color: transparent;
  }
}

.nav-circle-links {
  @include respond($break60) {
    text-align: right;
  }
}

.column-article {
  @include block-rule;
  @include container-escape-phone;

  padding-top: 1.125em;
  padding-bottom: 1.875em;

  @include respond($break40) {
    padding-bottom: 3.75em;
  }

  .heading-primary {
    @include respond($break60) {
      @include heading-quaternary;
    }
  }

  header {
    padding-right: $containerPaddingPhone;
    padding-left: $containerPaddingPhone;

    .label {
      padding-bottom: 1.15rem;
    }

    @include respond($break20) {
      // Override mobile only padding
      padding-right: 0;
      padding-left: 0;
    }
  }

  figure {
    padding-top: 1.375em;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.grid-article {
  @include container-escape-phone;

  header {
    padding-right: $containerPaddingPhone;
    padding-left: $containerPaddingPhone;

    .label {
      padding-bottom: 1.15rem;
    }

    @include respond($break20) {
      // Override mobile only padding
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// May be a <button>
.article-breakdown-primary-summary {
  @include vendor-transition(padding, $duration, $timing);
  @include button-unstyled;

  width: 100%;
  padding: 1.438em $containerPaddingPhone 1.75em $containerPaddingPhone;
  text-align: left; // OD

  .heading-figure-primary {
    padding-left: 1em;
  }

  &.caret-circled-down-toggle {
    @include icon-pre($caret-circled-down);

    position: relative;
    padding-left: 3.6em;

    @include respond($break20) {
      padding-left: 3.125em;
    }

    &::before {
      @include vendor-transition(transform, $duration, $timing);

      position: absolute;
      top: 1.438rem;
      left: 0.875rem;
      font-size: 2.063rem;
      color: $accent-primary;

      @include respond($break20) {
        left: 0;
      }
    }
  }

  &.open {
    padding-bottom: 1.125em;

    &.caret-circled-down-toggle {
      &::before {
        @include rotate(180deg, 50% 50%);
      }
    }
  }
}

.article-breakdown-primary {
  .heading-tertiary {
    margin-top: 0.8em;
    margin-bottom: 1.563em;

    @include respond($break40) {
      margin-top: 0.813em;
    }
  }
}

// Type
// --------------------------------------------------------
// Headings
// --------------------------
.heading-block {
  @include label-prototype;

  padding: 2.143em 2em;
  color: $neutral-white;
}

.label-block {
  @include label-prototype;

  padding: 1.714em 1.286em;

  @include respond($break40) {
    padding: 1.5em;
  }
}

.label-round {
  display: inline-block;
  min-width: 4.063rem;
  min-height: 4.063rem;
  padding-top: 1.438rem;
  margin-bottom: 0.5rem;
  color: $neutral-white;
  text-align: center;
  background-color: $neutral-black;
  border-radius: 100%;
}

.heading-circle {
  @include icon-pre($circle);

  position: relative;
  padding-left: 2.188rem;

  &::before {
    @include content-spectrum(color);

    position: absolute;
    top: 0;
    top: 50%;
    left: 0;
    margin-top: -0.783em;
    font-size: 1.438rem;
  }
}

// RTE Type
// --------------------------
// Special one-word class variations required by October
.labelGray {
  color: $neutral-gray60;
}

// Links
// --------------------------
// <a>
.link-black {
  @include linkBlack;
}

// Blockquote
// --------------------------
@mixin blockquote {
  @include copy-small;
  @include container-escape-phone;

  padding: 2.438rem 4.375rem 2.125rem;
  margin: 0 0 2.313rem;
  font-style: italic;
  text-align: center;
  background-color: $neutral-off-white;

  .label {
    @include label-prototype;

    display: block;
    margin-top: 1.5em;
    font-style: normal;
    color: $neutral-gray60;
  }
}

// Images
// --------------------------------------------------------
// <figcaption>
.content-caption {
  @include heading-tertiary;

  padding-top: 1.188rem;
  color: $neutral-gray60;
}

.caption-bottom.heading-tertiary {
  p {
    @include heading-tertiary;
  }
}

.caption-bottom-overlay {
  padding: 0.375rem 0 0.813rem;
  background-color: $neutral-white;

  .hero & {
    @include respond($break20, max) {
      padding-right: $containerPaddingPhone;
      padding-left: $containerPaddingPhone;
    }
  }
}

.caption-corner {
  padding: 0.3rem 0.75rem;

  @include respond($break40) {
    padding: 1.063em 1.25em 1em 1em;
    background-color: $neutral-white;
  }

  figure:hover & {
    .heading-primary {
      color: $linkHover;
    }
  }
}

.caption-corner-labeled {
  margin-top: -1.375rem;

  @include respond($break40) {
    margin-top: 0;
  }

  > .label {
    display: inline-block;
    padding: 0.5rem 0.875rem 0.438rem 0.75rem;
    background-color: $neutral-white;

    @include respond($break40) {
      padding: 0;
    }
  }

  .heading-primary {
    padding: 0 0.75rem;

    @include respond($break40) {
      padding: 0;
    }
  }
}

.hover-dialog {
  @include vendor-transition(opacity, $duration, $timing);

  padding: 1.625em 0;
  background-color: $neutral-white;
  opacity: 0;

  .touchevents & {
    opacity: 1;

    @include animation(fadeout-on-load 2s $timing 1s forwards);
  }

  a:hover & {
    opacity: 1;
  }

  .label {
    @include linkBlack;

    padding-bottom: 0.688rem;
  }
}

// <figure>
.map-pip-center {
  position: relative;
  overflow: hidden;

  &.primary::before,
  &.grand-ave::before {
    color: $spectrum-primary;
  }

  &.secondary::before,
  &.geffen-contemporary::before {
    color: $spectrum-secondary;
  }

  &.tertiary::before,
  &.pacific-design-center::before {
    color: $spectrum-tertiary;
  }

  &.quaternary::before,
  &.double-negative::before {
    color: $spectrum-quaternary;
  }

  a {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 48.056%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// Playbutton overlay on video thumbnails
.overlay-play {
  @include icon-pre($triangle-circled);

  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    // Declared in ems in same place as font-size
    width: 1.2em;
    height: 1.2em;
    padding-top: 0.1em;
    margin-top: -0.598em;
    margin-left: -0.598em;
    font-size: 2.2em;
    color: $accent-primary;
    text-align: center;
    background-color: $neutral-white;
    border-radius: 100%;
  }
}

.logo-static {
  @include logo-pre($logo-new, 120px);

  position: relative;
  z-index: 10;
  display: inline-block;
  color: $spectrum-secondary;

  @include respond($break60) {
    display: none;
  }

  &::before {
    font-size: 0.938rem;
  }

  .screen-header & {
    color: $neutral-white;
  }

  .overlay-open & {
    display: inline-block;
  }
}

.logo-animated {
  @include vendor-transition(height, $durationSlow, ease-out);

  position: relative;
  z-index: 10;
  display: none;
  max-width: 10em;
  height: 7.813em;

  .headroom--not-top & {
    @include vendor-transition(height, 0.15s, ease-out);

    height: 1.375rem;
  }

  @include respond($break60) {
    display: block;
  }

  .logo-back {
    @include logo-pre($logo-new, 80%);

    width: 9.875rem;
    padding: 1px 0 0 1px;

    &::before {
      font-size: 0.938rem;
      color: $spectrum-secondary;
    }
  }

  .logo-block {
    @include vendor-transition(top, $durationSlow, $timing);

    position: absolute;
    top: 0;
    width: 200px;
    height: 80px;
    background-color: $neutral-white;

    .headroom--not-top & {
      @include vendor-transition(top, 0.15s, ease-out);

      top: -8.813rem;
    }
  }

  .logo-cutout {
    @include logo-pre($logo-new);

    width: 200px;
    height: 80px;

    &::before {
      position: relative;
      top: 25px;
      color: $spectrum-secondary;
    }
  }

  .overlay-open & {
    display: none;
  }
}

// Article Lists
// --------------------------------------------------------
// <ul>
.list-sign-secondary {
  @include list-unstyled;

  li + li {
    margin-top: 2.375em;
  }

  h3 {
    @include sign-secondary;
    @include linkBlack;
  }
}

.list-titles-padded {
  li {
    .listing {
      a {
        line-height: 1.2;
        color: $neutral-black;

        @include copy-primary;
      }

      a:hover {
        color: $linkHover;
      }
    }
  }
}

.list-article-tiled {
  @include container-escape-phone;

  article,
  .article-figure {
    padding-bottom: 2.2rem;

    @include respond($break40) {
      padding-bottom: 3.75rem;
    }
  }

  article {
    @include block-rule;

    padding-top: 1.063rem;
    padding-right: $containerPaddingPhone;
    padding-left: $containerPaddingPhone;

    .heading-primary {
      margin: 1.25rem 0 2.063rem;
    }
  }

  figure {
    @include container-escape-phone;

    img {
      width: 100%;
      height: auto;
    }
  }

  .article-figure figure {
    margin-right: 0; //OD
    margin-left: 0; //OD
  }
}

.list-article-breakdown-show {
  @include list-unstyled;

  margin: 2.688em 0 3.063em;
  border-bottom: 1px solid $neutral-gray50;

  > li {
    border-top: 1px solid $neutral-gray50;
  }
}

.list-article-ruled {
  > li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-right: -1.25rem;
    margin-left: -1.25rem;

    a {
      display: block;

      div,
      h3 {
        color: $neutral-black;

        &.gray {
          color: $neutral-gray60;
        }
      }

      // &:hover {
      //   h3 {
      //     color: $neutral-gray50;
      //   }
      // }
    }

    + li {
      border-top: solid 6px $neutral-gray50;
    }

    @include respond($break40, min) {
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0;

      + li {
        border-top: none;
      }

      article > * {
        border-top: solid 3px $neutral-gray50;
      }
    }
  }
}

.list-text-two-column {
  .list-heading {
    @include heading-tertiary;

    font-weight: $semibold;
    border-bottom: 2px solid $neutral-gray50;
  }

  .list {
    margin-top: 6px;
    margin-bottom: 20px;

    li {
      @include copy-primary;

      page-break-inside: avoid;
      break-inside: avoid;

      &::after {
        height: 6px;
        content: "";
      }
    }
  }
}

.screen-overlay {
  .left {
    .program-details {
      @include heading-primary;

      font-weight: $regular;
    }
  }

  .screen-synopsis {
    button {
      @include button-unstyled;
      @include font-body;

      font-size: 0.8rem;
      text-transform: uppercase;
    }

    .screen-description {
      p {
        @include copy-screen;
      }
    }
  }

  .screen-grid {
    .program-artist {
      @include heading-tertiary;
    }

    .program-title {
      @include heading-tertiary;
    }

    .program-duration {
      @include copy-small;
    }

    .program-synopsis,
    .program-synopsis p {
      @include copy-small;
    }
  }

  .screen-calendar-grid {
    .program-artist,
    .program-title {
      @include heading-primary;
    }

    .program-duration {
      @include font-body(light);

      font-size: $type60;
      line-height: 1.2;
    }
  }
}

.screen-landing-header {
  margin-top: 25vh;
  text-align: center;
}

// Navigation Lists
// --------------------------------------------------------

.screen-header {
  .nav-primary .buttons .button-icon-bare {
    color: $neutral-white;
  }

  // .overlay-open & {
  //   background-color: $neutral-black;
  // }
}

.screen-details {
  .detail {
    @include heading-primary;

    font-size: $type30;
    text-align: center;

    @include respond($break30) {
      font-size: $type80;
      text-align: left;
    }

    &--small p {
      font: inherit;
      font-size: 85%;
    }
  }
}

// <ul>
.list-horizontal-labels {
  li {
    @include label-prototype;
    @include transition($duration color $timing);

    a {
      color: $neutral-gray60;
    }

    &.emphasized a {
      color: $accent-error;
    }

    &.active a,
    a:hover,
    a.active {
      color: $neutral-black;
    }
  }

  &.caret {
    li::after {
      display: inline-block;
      margin-left: 1.1em;
      color: $neutral-gray60;
      content: ">";
    }

    li:last-child::after {
      display: none;
    }
  }
}

.list-shop-breadcrumbs {
  li {
    @include label-prototype;
    @include font-body(bold);
    @include transition($duration color $timing);

    font-size: 0.95rem;
    text-transform: none;
    letter-spacing: normal;

    a {
      color: $neutral-gray60;
    }

    &.emphasized a {
      color: $accent-error;
    }

    &.active a,
    a:hover,
    a.active {
      color: $neutral-black;
    }
  }

  &.caret {
    li {
      margin-right: 0.5em;

      &::after {
        display: inline-block;
        margin-left: 0.5em;
        color: $neutral-gray60;
        content: "/";
      }

      &:last-child::after {
        display: none;
      }
    }
  }
}

// shopify mobile submenus -- only for active
li[data-has-mobilesubmenu] .submenu {
  display: none;
  list-style: none;
}

li[data-has-mobilesubmenu] .submenu a {
  color: #7f7f7f;
}

li.active[data-has-mobilesubmenu] .submenu {
  display: block;
}

li.mobileActive[data-has-mobilesubmenu] .submenu {
  display: block;
}

li.mobileActive[data-has-mobilesubmenu] .submenu .subActive {
  position: absolute;
  top: 0;
}

li.mobileActive[data-has-mobilesubmenu] .submenu .subActive a {
  width: 90vw;
}

li.mobileActive[data-has-mobilesubmenu] .submenu .subActive a::after {
  position: absolute;
  top: 2rem;
  right: 1.25rem;
  display: inline-block;
  margin-top: -0.244rem;
  /* stylelint-disable-next-line */
  font-family: moca-icons;
  font-size: 0.489rem;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: top;
  content: "\E60C";
  speak: none;
}

// horizontal nav item with submenu
li[data-has-submenu] {
  position: relative;

  > a {
    @include icon-post($caret-down);

    padding-right: 1.5em;

    &::after {
      position: absolute;
      top: 49%;
      right: 0;
      font-size: 7.5px;
      transform: translateY(-50%);
    }
  }

  ul {
    $_padding: 1em;

    position: absolute;
    top: 100%;
    left: -1 * $_padding;
    z-index: 1;
    display: block;
    display: none;
    width: 310px;
    min-width: calc(100% + 2 * #{$_padding});
    padding: $_padding;
    background-color: white;

    li {
      display: block;
    }
  }

  &.submenu-active {
    ul {
      display: block;
    }
  }
}

// <ul>
.list-label-nav {
  @include list-unstyled;

  padding: 0.688rem 0 1px; //NP
  li {
    a {
      @include label-prototype;
      @include linkBlack;

      position: relative;
      display: block;
      padding: 0 1.688rem;
      font-size: $type30;
      line-height: 2.188rem;
    }

    &.active a::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 0.563rem;
      height: 2.188rem;
      margin-top: -1.094rem;
      content: "";
      background-color: $accent-primary;
    }
  }
}

// <ul>
.list-dropdown {
  @include list-unstyled;

  position: relative;
  display: flex !important; /* stylelint-disable-line declaration-no-important */
  flex-direction: column;
  height: 0;
  padding-top: 3.875em;
  overflow: hidden;
  background-color: $neutral-off-white;

  li {
    a,
    button {
      @include label-prototype;
      @include linkBlack;

      display: block;
      padding: 1.375rem 1.25rem 1.312rem;
      outline: none;

      &:hover {
        color: $neutral-white;
        background-color: $accent-primary;
      }
    }
  }

  li.active {
    order: -1;
    margin-top: -3.875rem;

    a {
      @include icon-post($caret-down);

      &::after {
        position: absolute;
        top: 2rem;
        right: 1.25rem;
        margin-top: -0.244rem;
        font-size: 0.489rem;
      }
    }

    ul {
      @include list-unstyled;

      display: flex !important; /* stylelint-disable-line declaration-no-important */
      flex-direction: column;
      padding-left: 1rem;

      li[data-view-all-mobile-target] {
        display: none;

        &.open {
          display: block;
        }
      }

      li.last {
        order: 1;
      }

      a,
      button {
        color: $neutral-gray60;

        &:hover {
          background-color: initial;
        }

        &[data-view-all-mobile-trigger] {
          @include button-unstyled;

          padding: 1.375rem 1.25rem 1.312rem;

          &.open {
            position: relative;
            visibility: hidden;

            &::before {
              position: absolute;
              left: 1.25rem;
              visibility: visible;
              content: "View Less";
            }
          }
        }
      }
    }
  }

  @include respond($break40) {
    li {
      &.active a,
      a:hover,
      a.active {
        color: $linkColor;
      }
    }
  }
}

// <ul>
.nav-social {
  @include list-horizontal;

  background-color: $neutral-white;

  li + li {
    margin-left: 1em;
    vertical-align: bottom;
  }

  li a {
    font-size: 1.188em;
    color: $neutral-black;

    &:hover {
      color: $linkHover;
    }
  }
}

.nav-sticky {
  position: sticky;
  top: $header-height;
  top: calc(var(--alert-bar-height, 0px) + var(--header-height, #{$header-height}));

  > *:first-child {
    max-height: calc(100vh - var(--nav-sticky-top, 0));
    overflow: auto;
  }
}

// <nav> with nested <ul>
.nav-heading-primary {
  // Override block padding
  padding: 1.375rem 0;

  > ul {
    @include list-unstyled;

    li + li {
      margin-top: 0.625rem;
    }

    > li {
      > a {
        @include heading-primary;
        @include linkBlack;

        position: relative;
        display: block;
        padding: 0 1.25rem 0 2.688rem;
        line-height: 2.188rem;
      }

      &.active > a::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 8px;
        content: "";
        background-color: $accent-primary;
      }

      // Nested list styles
      ul {
        @include list-unstyled;

        display: flex !important; /* stylelint-disable-line declaration-no-important */
        flex-direction: column;
        margin-top: 0.563rem;

        li {
          position: relative;

          &[data-view-all-target] {
            display: none;

            &.open {
              display: block;
            }
          }

          &:first-child {
            margin-top: 1rem;
          }

          &.last {
            order: 1;
          }

          a,
          button {
            @include heading-tertiary;

            display: block;
            padding: 0 1.25rem 0 3.125rem;
            color: $neutral-gray60;

            &[data-view-all-trigger] {
              @include button-unstyled;

              padding: 0 1.25rem 0 3.125rem;

              &.open {
                position: relative;
                visibility: hidden;

                &::after {
                  position: absolute;
                  top: 0;
                  left: 3.125rem;
                  visibility: visible;
                  content: "View Less";
                }
              }
            }
          }
        }
      }
    }
  }
}

// <ul>
.list-heading-tertiary {
  @include list-horizontal;

  background-color: $neutral-white;

  .screen-header & {
    background-color: transparent;
  }

  li {
    a {
      @include heading-tertiary;

      .screen-header & {
        color: $neutral-white;
      }
    }

    &.active a {
      color: $neutral-black;
    }
  }

  li + li {
    margin-left: 1.656em;
  }
}

// Grid based, just block links with padding
.list-heading-quaternary {
  margin-top: 1.125em;

  a {
    @include heading-quaternary;

    display: block;
    margin-bottom: 2.188rem;
  }
}

.range {
  position: relative;

  &::before {
    position: absolute;
    top: 54%;
    left: 10%;
    z-index: 0;
    display: block;
    width: 80%;
    height: 3px;
    content: "";
    background-color: $neutral-gray50;

    @include respond($break40) {
      top: 38.5%;
    }
  }

  li {
    position: relative;
    z-index: 1;
    margin-top: 0;
    text-align: center;

    .heading-tertiary {
      display: none;
      line-height: 1.25rem;

      @include respond($break40) {
        display: block;
        font-size: 1rem;
      }

      @include respond($break60) {
        font-size: 1.25rem;
      }
    }

    a {
      @include vendor-transition(color, $duration, $timing);
    }

    .heading-figure-secondary {
      @include vendor-transition(all, $duration, $timing);

      visibility: hidden;

      @include respond($break40) {
        visibility: visible;
      }
    }
  }

  li.active {
    margin-top: -0.438em;

    .heading-figure-secondary {
      @include heading-figure-primary;

      visibility: visible;
    }

    a {
      color: $accent-primary;
    }
  }
}

.range-pip {
  @include vendor-transition(all, $duration, $timing);

  position: relative;
  display: inline-block;
  width: 1.155em;
  height: 1.155em;
  margin: 0.938em 0 1.375em;
  background-color: $neutral-gray50;
  border: 1px solid $neutral-white;
  border-radius: 100%;

  .active & {
    width: 2.5em;
    height: 2.5em;
    margin: 0.375em 0 0.75em;
    background-color: $accent-primary;

    &::after {
      position: absolute;
      bottom: -0.438em;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      margin-left: -0.438em;
      content: "";
      border-color: $accent-primary transparent transparent;
      border-style: solid;
      border-width: 0.5em 0.438em 0;
    }
  }
}

// <ul>
.list-circle-links {
  @include respond($break60) {
    display: inline-block;
    background-color: $neutral-gray10;
  }

  li {
    @include respond($break60) {
      padding: 1.313em 0 1.5em;
    }

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 0.938rem 0.5rem 2.375rem;
      background-color: $neutral-gray10;

      @include respond($break40) {
        padding: 1.25rem 0.938rem 1.25rem 2.375rem;
      }

      @include respond($break60) {
        padding: 0 0 0 1.5rem;
      }

      &::before {
        top: 50%;
        left: 0.875rem;
        margin-top: -0.438rem;

        @include respond($break60) {
          left: 0;
        }
      }
    }
  }
}

// <ul>
.list-ordinal-block {
  @include list-horizontal;

  display: block;
  text-align: right;

  li {
    a,
    button,
    span {
      @include label-prototype;

      display: inline-block;
      min-width: 4.813rem;
      padding: 1.813rem 0;
      color: $accent-primary;
      text-align: center;
      background-color: $neutral-gray10;
    }

    button,
    span {
      overflow: hidden;
      white-space: nowrap;
      border: none;

      &:hover {
        color: $neutral-white;
        background-color: $accent-primary;

        &::after,
        &::before {
          color: $accent-primary;
        }
      }
    }
  }

  li.active {
    a,
    button,
    span {
      color: $neutral-white;
      cursor: default;
      background-color: $accent-primary;
    }
  }

  li.caret-left {
    float: left;
    width: 49.5%;

    @include respond($break40) {
      float: none;
      width: auto;
    }

    a,
    button,
    span {
      @include icon-pre($caret-left);

      width: 100%;
      padding: 1.45rem 0 1.313rem 1.063rem;
      text-align: left;

      @include respond($break40) {
        width: auto;
        padding-right: 1.063rem;
      }

      &::before {
        display: inline-block;
        width: 2.188rem;
        padding: 0.563rem 0;
        margin-top: -2px; // NP
        margin-right: 0.938rem;
        font-size: 1.063rem;
        text-align: center;
        vertical-align: middle;
        background-color: $neutral-white;
        border-radius: 100%;
      }
    }
  }

  li.caret-right {
    float: right;
    width: 49.5%;

    @include respond($break40) {
      float: none;
      width: auto;
    }

    a,
    button,
    span {
      @include icon-post($caret-right);

      width: 100%;
      padding: 1.45rem 1.063rem 1.313rem 0;
      text-align: right;

      @include respond($break40) {
        width: auto;
        padding-left: 1.063rem;
      }

      &::after {
        display: inline-block;
        width: 2.188rem;
        padding: 0.563rem 0 0.563rem 3px;
        margin-top: -2px; // NP
        margin-left: 0.938rem;
        font-size: 1.063rem;
        text-align: center;
        vertical-align: middle;
        background-color: $neutral-white;
        border-radius: 100%;
      }
    }
  }

  // NB: Replicate display classes to allow use on inline block classes
  li.visible-hamburger {
    display: none;

    @include respond($break50) {
      display: inline-block;
    }
  }

  li.visible-tablet {
    display: none;

    @include respond($break40) {
      display: inline-block;
    }
  }
}

// <ul>
.sitemap {
  > li {
    > a,
    .row-secondary > a {
      @include heading-primary;

      color: $neutral-black;

      .block-primary & {
        color: $neutral-white;
      }

      &:hover {
        color: $neutral-gray60;
      }
    }
  }

  ul {
    li {
      a {
        // FABS head18
        @include font-head;

        font-size: $type50;

        .block-primary & {
          color: $neutral-gray50;

          &:hover {
            color: $neutral-gray60;
          }
        }
      }
    }
  }
}

// <ul>
.sitemap-vertical {
  > li {
    > a {
      @include heading-secondary;

      // Declared in appearance due to use with pseudo element
      position: relative;
      line-height: 1.4;
      color: $neutral-white;

      &:hover {
        color: $neutral-gray60;
      }
    }
  }

  > li.sitemap-nested > a {
    @include icon-post($caret-down);

    &::after {
      // Icon behavior
      @include vendor-transition(transform, $duration, $timing);

      position: absolute;
      top: 50%;
      right: 2.938em;
      margin-top: -0.25em;
      font-size: 0.5rem;
    }

    &.open {
      &::after {
        @include rotate(180deg, 50% 50%);
      }
    }
  }

  .active > a {
    color: $neutral-gray60;

    // Block that appears on active links
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 0.375rem;
      content: "";
      background-color: $neutral-gray60;
    }
  }

  ul {
    li {
      a {
        @include heading-tertiary;

        position: relative;
        display: block;
        padding: 0 1.688rem 0 2rem;
        line-height: 1.75;

        .block-primary & {
          color: $neutral-gray50;

          &:hover {
            color: $neutral-gray60;
          }
        }
      }
    }
  }
}

// Buttons
// --------------------------------------------------------
// Buttons are any kind of clickable linkable thing, so links, buttons, little icon chunks, etc.
// Images that link in navigation or as part of a larger article should be in images, layout,
// or the applicable list area.
// Replaces button with standard text link
.button-bare {
  @include button-unstyled;

  color: $linkColor;

  &:hover {
    color: $linkHover;
  }
}

.circle-link {
  @include icon-pre($circle);
  @include heading-tertiary;

  position: relative;
  display: inline-block;
  padding-left: 1.5rem;
  color: $neutral-black;

  &::before {
    position: absolute;
    top: 0.414em;
    left: 0;

    // Resets font-size (for structure) via rems
    font-size: 0.906rem;
  }

  &.primary::before,
  &.grand-ave::before {
    color: $spectrum-primary;
  }

  &.secondary::before,
  &.geffen-contemporary::before {
    color: $spectrum-secondary;
  }

  &.tertiary::before,
  &.pacific-design-center::before {
    color: $spectrum-tertiary;
  }

  &.quaternary::before,
  &.double-negative::before {
    color: $spectrum-quaternary;
  }

  .label {
    float: right;
    margin-top: 0.313rem; // NP
  }
}

// <a>
.circle-link-block {
  @include icon-pre($circle);
  @include heading-tertiary;

  position: relative;
  display: block;
  width: 100%;
  padding: 1.562rem 0 1.313rem 2.188rem;
  color: $neutral-white;

  &::before {
    position: absolute;
    top: 2rem;
    left: 0.75rem;

    // Resets font-size (for structure) via rems
    font-size: 0.906rem;
  }

  &.primary,
  &.grand-ave {
    background-color: $spectrum-primary;
  }

  &.secondary,
  &.geffen-contemporary {
    background-color: $spectrum-secondary;
  }

  &.tertiary,
  &.pacific-design-center {
    background-color: $spectrum-tertiary;
  }

  &.quaternary,
  &.double-negative {
    background-color: $spectrum-quaternary;
  }
}

// <a>
// NB: Additional overrides will be required to use with <button>
.button-inline-small {
  @include label-prototype;

  display: inline-block;
  padding: 0.563rem 1.125rem;
  color: $neutral-black;
  background-color: $neutral-gray10;
}

// <a>
// NB: Additional overrides will be required to use with <button>
.button-rect {
  @include label-prototype;

  display: inline-block;
  padding: 1.063rem 0.625em;
  line-height: inherit;
  color: $neutral-white;
  text-align: center;
  background-color: $neutral-black;
  border: 1px solid $neutral-black;
  border-radius: 0;
  outline: none;

  @include respond($break40) {
    min-width: 8.75rem;

    & + & {
      margin-left: 0.188rem;
    }
  }

  &.primary {
    background-color: $accent-primary;
    border-color: $accent-primary;

    &:active {
      color: $accent-primary;
      background-color: $neutral-white;
    }
  }

  &.secondary {
    color: $accent-primary;
    background-color: $neutral-white;
    border-color: $accent-primary;

    &:active {
      color: $neutral-white;
      background-color: $accent-primary;
    }
  }

  &.dull {
    color: $neutral-white;
    background-color: $neutral-gray10;
    border-color: $neutral-gray10;
  }

  &.minimalist {
    min-width: 0;
    padding: 1.25rem 0.8em;
    font-size: $type30;
    color: $neutral-white;
    background-color: transparent;
    border: 2px solid $neutral-white;
    border-color: $neutral-white;

    @include respond($break30) {
      padding: 0.45rem 0.8em;
      font-size: 0.575rem;
    }
  }
}

.button-full {
  @extend .button-rect;

  display: block;
  width: 100%;

  & + & {
    margin-top: 0.35rem;
  }

  .label-heading + & {
    margin-top: 0.563rem;
  }
}

// <button> or <a>
.button-icon-bare {
  @include button-unstyled;

  color: $accent-primary;
}

.button-match-select {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

// <button>
.button-share {
  @include button-unstyled;

  // Typography mixin
  @include label-prototype;

  &::before {
    padding-right: 0.721rem;
    font-size: 1.063rem;
    vertical-align: top;
  }

  &.button-popup {
    min-width: $containerColumnPadding;
    text-align: right;
    background-color: $neutral-white;

    .cta-container & {
      min-width: 13.875rem;
    }
  }

  .article-top-right &::before {
    display: none;
  }
}

// <button>
.button-more {
  @include vendor-transition(color, $duration, $timing);
  @include button-unstyled;
  @include icon-pre($plus);

  // Typography mixin
  @include label-prototype;

  &:hover {
    color: $linkColor;
  }

  &::before {
    padding-right: 0.721rem;
    font-size: 1.063rem;
    vertical-align: top;
  }
}

// <a>
// NB: Additional overrides will be required to use with <button>
.button-calendar {
  @include icon-pre($calendar-plus);
  @include linkBlack;

  // Typography mixin
  @include label-prototype;

  &::before {
    position: relative;
    top: -0.25rem; // NP
    font-size: 1.875rem;
    vertical-align: middle;
  }
}

.button-caret-down-trigger {
  @include button-unstyled;
  @include label-prototype;
  @include icon-post($caret-down);

  width: 100%;
  padding: 1.375rem 0;
  color: $neutral-white;
  text-align: center;
  background-color: $accent-primary;

  &::after {
    width: 2.063rem;
    height: 2.063rem;
    padding-top: 0.813rem;
    margin-left: 0.563rem;
    font-size: 0.489rem;
    color: $neutral-black;
    vertical-align: middle;
    background-color: $neutral-white;
    border-radius: 100%;
  }

  &.neutral {
    color: $neutral-black;
    background-color: $neutral-gray10;

    &::after {
      background-color: $neutral-gray10;
    }
  }
}

.pip-link {
  @include label-prototype;
  @include icon-pre($pip);

  position: relative;
  display: block;
  padding-left: 2.5rem;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    color: $accent-primary;
  }

  p + & {
    margin-top: 2.75rem;
  }
}

// <a> or <button>
.icon-x {
  @include icon-pre($x);
  @include button-unstyled;

  color: $accent-primary;

  a:hover &,
  &:hover {
    color: $linkHover;
  }

  &.button-close {
    font-size: 1.875em;
  }
}

.icon-x-circled {
  @extend .icon-x;

  width: 35px;
  height: 35px;
  padding: 6px;
  border: 2px solid;
  border-radius: 50%;
}

.icon-caret-circle-right {
  @include button-unstyled;
  @include linkBlack;
  @include icon-post($caret-right);

  .icon-caret-circle-left + & {
    margin-left: 0.5em;

    @include respond($break40) {
      margin-left: 0.875em;
    }
  }

  &::after {
    display: inline-block;
    width: 2.125em;
    height: 2.125em;
    padding: 0.563em 0 0 0.875em;
    vertical-align: middle;
    background-color: $neutral-white;
    border-radius: 100%;
  }
}

.icon-caret-circle-left {
  @include button-unstyled;
  @include linkBlack;
  @include icon-pre($caret-left);

  &::before {
    display: inline-block;
    width: 2.125em;
    height: 2.125em;
    padding: 0.563em 0 0 0.688em;
    vertical-align: middle;
    background-color: $neutral-white;
    border-radius: 100%;
  }
}

.button-caret-circle-right {
  @extend .icon-caret-circle-right;

  padding: 1.063em 1.375em;
  background-color: $neutral-gray10;

  &::after {
    margin-left: 1em;
    text-align: left;

    @include respond($break40) {
      margin-left: 5.313em;
    }
  }
}

.button-caret-circle-left {
  @extend .icon-caret-circle-left;

  padding: 1.063em 1.375em;
  background-color: $neutral-gray10;

  &::before {
    margin-right: 1em;
    text-align: left;

    @include respond($break40) {
      margin-right: 5.313em;
    }
  }
}

.icon-arrow-circled-right {
  @include button-unstyled;
  @include icon-post($arrow-circled-right);

  line-height: 2.625rem;

  &::after {
    margin-left: 0.688rem;
    font-size: 2.625rem;
  }
}

.icon-arrow-circled-left {
  @include button-unstyled;
  @include icon-post($arrow-circled-left);

  line-height: 2.625rem;

  &::after {
    margin-left: 0.688rem;
    font-size: 2.625rem;
  }
}

.icon-plus-circle {
  @include button-unstyled;
  @include icon-pre($plus);

  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border: 2px solid;
  border-radius: 100%;

  &::before {
    padding-top: 0.767em;
    font-size: 0.938rem;
  }

  &.primary {
    color: $accent-primary;
  }
}

.button-filter {
  @include button-unstyled;
  @include icon-pre($funnel);

  position: absolute;
  top: 0;
  right: 0;
  max-height: 4.688em;
  padding: 1.25em 1.5em;
  margin-left: 1.25rem;
  background-color: $neutral-gray10;

  @include respond($break60) {
    max-height: 5.125em;
    padding: 1.688em 1.5em;
  }

  &::before {
    font-size: 1.5rem;
    line-height: 1.417;
  }

  &.open {
    color: $neutral-white;
    background-color: $accent-primary;
  }

  &.invisible-desktop {
    .ie9 & {
      display: block;
    }
  }
}

// <a>
.button-composite {
  @include label-prototype;

  display: block;
  padding: 1em 2em; // Stub padding, currently only used in locations heading
  color: $neutral-white;
  text-align: center;
  background-color: $spectrum-composite;
}

.button-cart {
  @include label-prototype;

  color: #4e484a; // a bit lighter than $accent-primary since the cart icon is heavier

  .icon-cart {
    font-size: 1.063rem;

    @include respond($break60) {
      font-size: 1.25rem;
    }

    &::after {
      margin-top: 0.125rem;
      vertical-align: top;

      @include respond($break60) {
        vertical-align: bottom;
      }
    }
  }

  &:hover {
    color: $neutral-black;

    .icon-cart {
      color: $neutral-black;
    }
  }
}

// Tables
// --------------------------------------------------------
// <nav> wrapper
.table-alphabet-nav {
  padding: 0 1.625em 1.35em;

  table {
    tr {
      td {
        @include label-prototype;

        padding-left: 1.875rem;
        font-size: $type50;
        line-height: 2.7rem;
        text-align: center;

        a {
          @include linkBlack;

          position: relative;
          display: block;
        }

        a.active::before {
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 2.188rem;
          height: 0.438rem;
          margin-left: -1.1rem;
          content: "";
          background-color: $accent-primary;
        }
      }

      td:first-child {
        padding-left: 0;
        text-align: left;
      }
    }
  }
}

// Id scoping required to override theme defaults
// ID styling required to override Shopify
/* stylelint-disable selector-max-id */
#cart .cart-minimal {
  thead {
    tr {
      th {
        @extend .heading-cart;
      }
    }
  }

  tbody {
    tr {
      border-top: 6px solid $neutral-gray40;

      @include respond($break40) {
        border: 0;
      }

      td {
        position: relative;
        border: 0;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          width: 100%;
          height: 6px;
          content: "";
          background-color: $neutral-gray40;

          @include respond($break40) {
            display: block;
          }
        }
      }

      td.item {
        a {
          font-size: 1.125rem;
          color: $neutral-black;

          .variant_title {
            color: $neutral-black;
          }
        }
      }

      td.qty {
        color: $neutral-black;
      }

      td.price {
        @include heading-primary;

        font-size: $type90;
        color: $neutral-black;
      }

      td.summary-price {
        .total {
          @include heading-primary;

          font-size: $type90;
          color: $neutral-black;
        }
      }

      td.remove {
        font-size: 1.125rem;
        color: $neutral-gray40;
      }
    }
  }
}
/* stylelint-enable selector-max-id */

// Forms
// --------------------------------------------------------
@mixin input-simple {
  // FABS head18
  @include appearance(none);
  @include font-head;

  width: 100%;
  padding: 0.938rem 1.438rem;
  font-size: $type50;
  background-color: $neutral-gray30;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;

  .input-icon & {
    padding-right: 3.125rem;
  }
}

// Currency wrapper for simple input
.field-error-single {
  @include label-prototype;

  margin-bottom: 7px;
}

.input-dollars {
  position: relative;

  input {
    padding-left: 2rem;
    text-align: right;
  }

  &::before {
    position: absolute;
    top: 0.813rem;
    left: 0.938rem;
    font-size: $type50;
    content: "$";

    @include font-head;
  }
}

.form-simple {
  .container-col-two-thirds & {
    padding: 0 $containerPaddingPhone;

    @include respond($break20) {
      padding: 0 $containerPaddingTablet;
    }

    @include respond($break60) {
      padding: 0;
    }
  }

  label {
    @include heading-secondary;

    display: inline-block;
    margin-bottom: 0.313em;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="date"] {
    @include input-simple;

    // Adjusted padding for error compatible borders
    padding: 0.75rem 1.438rem;
    border: 3px solid $neutral-gray30;
  }

  .light {
    input[type="text"],
    input[type="email"] {
      color: $neutral-black;
      background-color: $neutral-white;
      border: 3px solid $neutral-white;
    }

    span {
      color: $neutral-off-black;
    }
  }

  // Mailchimp error class
  input.mce_inline_error {
    background-color: $neutral-white;

    // Important required to override mailchimp default styles
    border: 3px solid $accent-error !important; /* stylelint-disable-line declaration-no-important */
  }

  div.mce_inline_error {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  // Duplicated to fix inheritance
  .input-icon input {
    padding-right: 3.125rem;
  }

  .label-secondary {
    @include font-body(light);

    float: right;
    font-size: $type50;
    text-transform: none;
  }

  .field-group .col-half {
    margin-top: 1.3em;

    @include respond($break40) {
      margin-top: 0;
    }
  }

  .field-group + .field-group {
    margin-top: 1.3em;

    @include respond($break40) {
      margin-top: 1.875em;
    }
  }
}

.field,
.field-group {
  & + & {
    margin-top: 1.35em;
  }
}

.additional-details {
  margin-top: 34px;

  > .col-half {
    width: 100%;

    @include respond($break55) {
      width: 46%;
    }
  }

  .inner-left {
    .field {
      margin-bottom: 40px;

      &.space-bottom-mobile {
        margin-bottom: -8px;

        @include respond($break55) {
          margin-bottom: 40px;
        }
      }
    }

    .field:last-child {
      margin-top: 12px;
      margin-bottom: 22px;
    }

    .col-half {
      width: 100%;

      @include respond($break55) {
        width: 48%;
      }
    }

    .col-half + .col-half {
      margin-top: 13px;
      margin-left: 0;

      @include respond($break55) {
        margin-top: 0;
        margin-left: 3.98%;
      }
    }
  }

  .label {
    padding-bottom: 13px;
  }

  .labelGray {
    margin-top: -6px;
    margin-bottom: 13px;
  }
}

.cart-buttons {
  .button-rect {
    width: 100%;
    margin-top: -10px;

    @include respond($break55) {
      padding: 8% 20%;
    }
  }
}

// Reusable form components
// --------------------------
// Input Select (Simple dropdown)
%input-select {
  @include icon-post($caret-down);

  position: relative;
  display: inline-block;
  min-width: 8.75em;

  &.inline {
    margin-right: 0.75rem;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 2.5em;
    margin-top: -0.6em;
    font-size: 0.6rem;
    pointer-events: none;
  }

  select {
    @include appearance(none);
    @include label-prototype;

    display: inline-block;
    width: 100%;
    padding: 1.5em 4.286em 1.5em 1.5em;
    margin: 0;
    cursor: pointer;
    background-color: $neutral-gray10;
    border: none;
    border-radius: 0;

    &:focus {
      outline: none;
    }

    &:focus:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $neutral-black;
    }

    // Hides dropdown arrow in IE10 and up
    &::-ms-expand {
      display: none;
    }
  }

  &.primary {
    color: $accent-primary;
  }
}

.input-select {
  @extend %input-select;

  &.request-form {
    display: block;

    select {
      padding: 1.1em 4.286em 1.2em 1.5em;
    }
  }
}

.input-date {
  input[type="date"] {
    @include label-prototype;
  }
}

.request-errors {
  li,
  p {
    @include copy-small;
  }
}

// Wrapper class used to style Shopify's generated
// selector-wrapper classes
.input-wrapper {
  display: inline;

  &.invisible {
    display: none;
  }

  .selector-wrapper {
    display: inline-block;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
  }

  label {
    @include label-prototype;

    display: block;
    margin-bottom: 0.375rem;
    color: $neutral-off-black;
  }
}

.input-select-wide {
  @extend %input-select;

  select {
    padding: 2.125em 4.911em 2.125em 2.125em;
  }

  &::after {
    right: 3.3em;
  }

  @include respond($break40) {
    min-width: 13.125em;
  }
}

.input-select-skinny {
  @extend %input-select;

  select {
    padding: 2.125em 4.911em 2.125em 2.125em;
  }

  &::after {
    right: 3.3em;
  }

  @include respond($break40) {
    min-width: 13.125em;
  }
}

.input-simple {
  @include input-simple;
}

.textarea-simple {
  @include input-simple;

  padding: 0.938rem 1.438rem 5.938rem;
  margin-bottom: 42px;
  resize: vertical;
  border: none;

  @include respond($break55) {
    padding: 0.938rem 1.438rem;
  }
}

// ID required for override
#note {
  /* stylelint-disable-line selector-max-id */
  margin-top: 0;
}

.input-integer {
  @include input-simple;
  @include font-head;

  display: inline-block;
  width: auto;
  padding: 10px 8px;
  font-size: $type90;
  text-align: center;
}

.input-icon {
  position: relative;
  font-size: $type50;

  span {
    position: absolute;
    top: 50%;
    right: 1.375rem;
    margin-top: $type50 * -0.5;
  }
}

// Google CSE styles
// --------------------------------------------------------
.search-neutral {
  input {
    @include font-head;

    width: 100%;
    height: 3.563rem;
    padding: 0 1em;
    font-size: $type80;
    text-align: right;
    background-color: $neutral-gray10;
    border: none;
    outline: none;
  }

  .gsc-control-cse {
    border: none;
  }

  .gsc-control-cse,
  form.gsc-search-box,
  table.gsc-search-box,
  .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding: 0;
    margin: 0;
  }

  .gsc-input-box,
  .gsc-input-box-hover {
    position: relative;
    height: auto;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none; // OD

    td {
      padding: 0;
    }

    input.gsc-input {
      // !importants required to override default declarations from Google CSE
      @include font-head;

      height: 3.563rem !important; /* stylelint-disable-line declaration-no-important */
      padding: 0 1em !important; /* stylelint-disable-line declaration-no-important */
      font-size: $type80;
      text-align: right;
      background: $neutral-gray10 !important; /* stylelint-disable-line declaration-no-important */
      background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
      border-radius: 0;

      .container-header-fixed & {
        height: 3.375rem !important; /* stylelint-disable-line declaration-no-important */

        @include respond($break60) {
          height: 3.563rem !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }

  .gsib_b {
    display: none;
  }

  .gsc-search-box {
    float: right;
  }

  .gsc-search-button {
    display: none;
  }
}

// October
// --------------------------------------------------------
.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {
  // Important required to override framework CSS
  background-color: $accent-primary !important; /* stylelint-disable-line declaration-no-important */
}

//.block-off-white {
//  background-color: $neutral-off-white;
//}
//
//.block-spectrum {
//  &.primary {
//    background-color: $spectrum-primary;
//    color: $neutral-white;
//  }
//  &.secondary {
//    background-color: $spectrum-secondary;
//    color: $neutral-white;
//  }
//  &.tertiary {
//    background-color: $spectrum-tertiary;
//    color: $neutral-white;
//  }
//  &.quaternary {
//    background-color: $spectrum-quaternary;
//    color: $neutral-white;
//  }
//}
//
//.card-aside {
//  background-color: $neutral-gray10;
//  padding: 1.25em 1.5em;
//}
//
//.card-article {
//  padding: 0.125em 0 1.5em;
//  @include respond($break40) {
//    padding: 2.375em 0;
//  }
//}
//
//.card-thumbnail {
//  display: none;
//  padding: 1.25em 0 1.438em;
//  @include respond($break40) {
//    display: block;
//  }
//  img {
//    display: block;
//    width: 100%;
//  }
//}
//
//.card-article-summary {
//  @include copy-primary;
//  display: none;
//  @include respond($break40) {
//    display: block;
//  }
//}
//
//.card-label {
//  @include clearfix;
//  @include label-prototype;
//  .left {
//    float: left;
//  }
//  .right {
//    float: right;
//    text-align: right;
//  }
//}
//
//.nav-popup {
//  @include clearfix;
//  width: 100%;
//  .card-aside + & .popup-hidden{
//    background-color: $neutral-gray10;
//  }
//  .nav-social {
//    opacity: 0;
//    visibility: hidden;
//  }
//  &:hover {
//    .nav-social {
//      opacity: 1;
//      visibility: visible;
//    }
//  }
//}
//
//.popup-hidden {
//  @include clearfix;
//}
//
//.nav-popup-trigger {
//  float: right;
//  padding-top: 0.938em;
//}
//
//.block-pagination-both {
//  background-color: $neutral-off-white;
//  padding: 0.875em;
//  @include respond($break40) {
//    padding: 1.313em 2.188em 1.313em 1.313em;
//  }
//  .label {
//    line-height: 2.429;
//  }
//  .right {
//    float: right;
//    a {
//      margin-left: 0.875em;
//    }
//  }
//}
//
//.block-pagination-filter {
//  @include button-unstyled;
//  @include icon-pre($funnel);
//  background-color: $neutral-off-white;
//  padding: .875em 1.5em;
//  @include respond($break40) {
//    padding: 1.313em 1.5em;
//  }
//  &::before {
//    font-size: 1.5rem;
//    line-height: 1.417;
//  }
//}
//
//// Buttons, Links
//// --------------------------------------------------------
//@mixin circle-link {
//  @include font-head;
//  @include icon-pre($circle);
//  display: block;
//  font-size: $type60;
//  color: $neutral-black;
//  padding-left: 1.2em;
//  position: relative;
//  &::before {
//    position: absolute;
//    top: 0.414em;
//    left: 0;
//    font-size: 0.906rem;
//  }
//  &.primary:before {
//    color: $spectrum-primary;
//  }
//  &.secondary:before {
//    color: $spectrum-secondary;
//  }
//  &.tertiary:before {
//    color: $spectrum-tertiary;
//  }
//  &.quaternary:before {
//    color: $spectrum-quaternary;
//  }
//}
//
//.circle-link {
//  @include circle-link;
//}
//
//.button-icon {
//  @include button-unstyled;
//  color: $accent-primary;
//}
//
//.button-share {
//  @include button-unstyled;
//  @include label-prototype;
//  @include icon-pre($arrow-curve);
//  &::before {
//    font-size: 1.214em;
//    padding-right: 0.824em;
//    vertical-align: top;
//  }
//}
//
//// <a> or <button>
//.button-caret-circle-right {
//  @include button-unstyled;
//  @include linkBlack;
//  @include icon-pre($caret-right);
//  &::before {
//    display: inline-block;
//    width: 2.125em;
//    height: 2.125em;
//    background-color: $neutral-white;
//    border-radius: 100%;
//    padding: 0.563em 0 0 0.875em;
//  }
//}
//
//.button-caret-circle-left {
//  @include button-unstyled;
//  @include linkBlack;
//  @include icon-post($caret-left);
//  &::after {
//    display: inline-block;
//    width: 2.125em;
//    height: 2.125em;
//    background-color: $neutral-white;
//    border-radius: 100%;
//    padding: 0.563em 0 0 0.688em;
//  }
//}
//
//// Singleton Blocks
//// --------------------------------------------------------
//.nav-hamburger {
//  background-color: $accent-primary;
//}
//
//.hamburger-close {
//  @include button-unstyled;
//  color: $neutral-white;
//  position: absolute;
//  top: 1.125em;
//  right: 1.687rem;
//  // Local z-index (base 200)
//  z-index: 10;
//  &:hover {
//    color: $linkHover;
//  }
//}
//
//.block-footer {
//  border-top: $sectionRule;
//  padding-top: 1.563em;
//  position: relative;
//  .nav-social {
//    position: static;
//    padding: 1.25em 0 1.188em;
//    @include respond($break40) {
//      padding: 0;
//      position: absolute;
//      bottom: 1.687rem;
//      right: 0;
//    }
//    @include respond($break70) {
//      top: 1.687rem;
//      right: 0;
//    }
//  }
//  .form-simple {
//    max-width: 21.875rem;
//  }
//  .fixed-two-thirds {
//    position: relative;
//  }
//  .links {
//    padding-right: 2.5rem;
//  }
//  .buttons {
//    position: absolute;
//    top: 0;
//    right: 0;
//  }
//  .search-popup {
//    right: 2.5rem;
//    &.open {
//      left: 0;
//    }
//  }
//}
